import type {RouteLocationRaw} from 'vue-router'
import * as Sentry from '@sentry/nuxt';

export default function useAuth() {
  const account = useState<components["schemas"]["Account"]|null>('auth_account', () => null)
  const isAuthenticated = computed<boolean>(() => !!account.value)

  const hasPermission = (permission: string) => {
    const userPerms = account.value?.permission_set
    if (!userPerms) return false
    return userPerms.includes(permission)
  }

  const hasPermissions = (permissions: string[]) => {
    const userPerms = account.value?.permission_set
    if (!userPerms) return false
    return permissions.every(permission => userPerms.includes(permission))
  }

  const logout = async (to?: RouteLocationRaw) => {
    const {$api} = useNuxtApp()
    await $api('token/delete/', {
      method: 'POST',
    })
      .then(() => {
        account.value = null

        Sentry.setUser(null)

        navigateTo(to ?? { name: 'account-login'})
      })
  }

  const setAccount = async () => {
    const {$api} = useNuxtApp()
    await $api<components["schemas"]["Account"]>('account/')
      .then(response => {
        account.value = response

        Sentry.setUser({
          id: response.id,
          email: response.email,
          username: response.email,
        })
      })
      .catch(() => {
        navigateTo({name: 'account-login'})
      })
  }

  return {
    account,
    hasPermission,
    hasPermissions,
    isAuthenticated,
    logout,
    setAccount,
  }
}
