
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexpCjgZl67bmMeta } from "/home/runner/work/medlawyer/medlawyer/frontend/pages/account/index.vue?macro=true";
import { default as loginQ5OnCMxFFgMeta } from "/home/runner/work/medlawyer/medlawyer/frontend/pages/account/login.vue?macro=true";
import { default as _91token_935QYRwPg2DmMeta } from "/home/runner/work/medlawyer/medlawyer/frontend/pages/account/password-reset/[uidb64]/[token].vue?macro=true";
import { default as complete3OffXashPOMeta } from "/home/runner/work/medlawyer/medlawyer/frontend/pages/account/password-reset/complete.vue?macro=true";
import { default as doneuIPkC8i0UIMeta } from "/home/runner/work/medlawyer/medlawyer/frontend/pages/account/password-reset/done.vue?macro=true";
import { default as indexsXaFaUiU8JMeta } from "/home/runner/work/medlawyer/medlawyer/frontend/pages/account/password-reset/index.vue?macro=true";
import { default as password_45resetMi4YzjVNzpMeta } from "/home/runner/work/medlawyer/medlawyer/frontend/pages/account/password-reset.vue?macro=true";
import { default as messagesZSMqkpQRhPMeta } from "/home/runner/work/medlawyer/medlawyer/frontend/pages/filing/cases/[id]/messages.vue?macro=true";
import { default as _91id_93MXxYr27MWMMeta } from "/home/runner/work/medlawyer/medlawyer/frontend/pages/filing/cases/[id].vue?macro=true";
import { default as indexM9eTATB4X7Meta } from "/home/runner/work/medlawyer/medlawyer/frontend/pages/filing/cases/index.vue?macro=true";
import { default as _91id_93BwFhyYfdAYMeta } from "/home/runner/work/medlawyer/medlawyer/frontend/pages/filing/patients/[id].vue?macro=true";
import { default as index4bggoMBfDaMeta } from "/home/runner/work/medlawyer/medlawyer/frontend/pages/filing/patients/index.vue?macro=true";
import { default as filingcRrnvfxfgSMeta } from "/home/runner/work/medlawyer/medlawyer/frontend/pages/filing.vue?macro=true";
import { default as indexeT0HHTehOPMeta } from "/home/runner/work/medlawyer/medlawyer/frontend/pages/index.vue?macro=true";
import { default as indexomV8dR9CkNMeta } from "/home/runner/work/medlawyer/medlawyer/frontend/pages/users/index.vue?macro=true";
import { default as usersWDmiw68uL2Meta } from "/home/runner/work/medlawyer/medlawyer/frontend/pages/users.vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    meta: indexpCjgZl67bmMeta || {},
    component: () => import("/home/runner/work/medlawyer/medlawyer/frontend/pages/account/index.vue")
  },
  {
    name: "account-login",
    path: "/account/login",
    meta: loginQ5OnCMxFFgMeta || {},
    component: () => import("/home/runner/work/medlawyer/medlawyer/frontend/pages/account/login.vue")
  },
  {
    name: password_45resetMi4YzjVNzpMeta?.name,
    path: "/account/password-reset",
    meta: password_45resetMi4YzjVNzpMeta || {},
    component: () => import("/home/runner/work/medlawyer/medlawyer/frontend/pages/account/password-reset.vue"),
    children: [
  {
    name: "account-password-reset-uidb64-token",
    path: ":uidb64()/:token()",
    component: () => import("/home/runner/work/medlawyer/medlawyer/frontend/pages/account/password-reset/[uidb64]/[token].vue")
  },
  {
    name: "account-password-reset-complete",
    path: "complete",
    component: () => import("/home/runner/work/medlawyer/medlawyer/frontend/pages/account/password-reset/complete.vue")
  },
  {
    name: "account-password-reset-done",
    path: "done",
    component: () => import("/home/runner/work/medlawyer/medlawyer/frontend/pages/account/password-reset/done.vue")
  },
  {
    name: "account-password-reset",
    path: "",
    component: () => import("/home/runner/work/medlawyer/medlawyer/frontend/pages/account/password-reset/index.vue")
  }
]
  },
  {
    name: "filing",
    path: "/filing",
    meta: filingcRrnvfxfgSMeta || {},
    component: () => import("/home/runner/work/medlawyer/medlawyer/frontend/pages/filing.vue"),
    children: [
  {
    name: "filing-cases-id",
    path: "cases/:id()",
    component: () => import("/home/runner/work/medlawyer/medlawyer/frontend/pages/filing/cases/[id].vue"),
    children: [
  {
    name: "filing-cases-id-messages",
    path: "messages",
    component: () => import("/home/runner/work/medlawyer/medlawyer/frontend/pages/filing/cases/[id]/messages.vue")
  }
]
  },
  {
    name: "filing-cases",
    path: "cases",
    component: () => import("/home/runner/work/medlawyer/medlawyer/frontend/pages/filing/cases/index.vue")
  },
  {
    name: "filing-patients-id",
    path: "patients/:id()",
    component: () => import("/home/runner/work/medlawyer/medlawyer/frontend/pages/filing/patients/[id].vue")
  },
  {
    name: "filing-patients",
    path: "patients",
    component: () => import("/home/runner/work/medlawyer/medlawyer/frontend/pages/filing/patients/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/medlawyer/medlawyer/frontend/pages/index.vue")
  },
  {
    name: usersWDmiw68uL2Meta?.name,
    path: "/users",
    meta: usersWDmiw68uL2Meta || {},
    component: () => import("/home/runner/work/medlawyer/medlawyer/frontend/pages/users.vue"),
    children: [
  {
    name: "users",
    path: "",
    component: () => import("/home/runner/work/medlawyer/medlawyer/frontend/pages/users/index.vue")
  }
]
  }
]